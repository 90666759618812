<template>
    <div>
        <p class="text">
            {{ $t('faq.flexIptv.step1.searchFlexIptv') }}
            <a :href="flexIptvAppLink" target="_blank" :rel="'noreferrer noopener'" class="link">
                {{ flexIptvAppLink }}
            </a>
        </p>
        <div class="screenshot">
            <img src="../../assets/flexIptv/screen-1.png" :alt="$t('faq.flexIptv.step1.searchFlexIptv')" :width="219" :height="141" class="step-image">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            flexIptvAppLink: "https://apps.apple.com/ca/app/flex-iptv/id1182930255",
        }
    }
}
</script>

<style scoped>
@import "../../../../components/styles/guides-default-styles.css";
</style>
